import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    children: [
      {
        path: "Index",
        name: "Index",
        component: () => import("../views/Index/Index.vue"),
      },
      {
        path: "User",
        name: "User",
        component: () => import("../views/User/UseHistory.vue"),
      },
      {
        path: "ApiData",
        name: "ApiData",
        component: () => import("@/views/ApiData/ApiData.vue"),
      },
      {
        path: "AppDistinguish",
        name: "AppDistinguish",
        component: () => import("@/views/AppDistinguish/AppDistinguish.vue"),
      },
      // {
      //   path: "SrcDistinguish",
      //   name: "SrcDistinguish",
      //   component: () => import("@/views/SrcDistinguish/SrcDistinguish.vue"),
      // },
      {
        path: "MaterialManage",
        name: "MaterialManage",
        component: () => import("@/views/MaterialManage/MaterialManage.vue"),
      },
      {
        path: "Recharge",
        name: "Recharge",
        component: () => import("../views/Recharge/Recharge.vue"),
      },
      //  购买记录路由
      {
        path: "BuyHistory",
        name: "BuyHistory",
        component: () => import("@/views/BuyHistory/BuyHistory.vue"),
      },
      //  用户管理路由
      {
        path: "UserManager",
        name: "UserManager",
        component: () => import("@/views/Admin/SetUser.vue"),
      },
      //  商品管理路由
      {
        path: "StoreManager",
        name: "StoreManager",
        component: () => import("@/views/Admin/SetStore.vue"),
      },
      //  所有充值记录
      {
        path: "GetAllRecharge",
        name: "GetAllRecharge",
        component: () => import("@/views/Admin/GetAllRecharge"),
      },
      //  例子
      {
        path: "Example",
        name: "Example",
        component: () => import("@/views/Example/Example"),
      },
      //  我是代理商
      {
        path: "Agent",
        name: "Agent",
        component: () => import("@/views/Agent/Agent"),
      },
      //  VNC远程
      {
        path: "VNC",
        name: "VNC",
        component: () => import("@/views/VNC/VNC"),
      },
      //  所有卡密
      {
        path: "Keys",
        name: "Keys",
        component: () => import("@/views/Keys/Keys"),
      },
      {
        path: "NetCheck",
        name: "NetCheck",
        component: () => import("@/views/NetCheck/NetCheck"),
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // {
  //   path: '/Personnel',
  //   name: 'Personnel',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "Personnel" */ '../views/Personnel/Personnel.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
