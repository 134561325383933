<template>
  <div id="app">
    <!-- 哈哈哈 -->
    <router-view v-if="isShow" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // 控制router-view的隐藏与显示
      isShow: true,
    };
  },
  provide() {
    // 提供可注入子组件属性
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      // 定义加载方式
      // 先取消当前路由的视图展示，待dom重新渲染完毕，再让当前路由视图展示
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    endTimeFn() {
      //过期自动退出登录
      console.log("过期");
      // 当前时间戳
      let nowTime = new Date().getTime();
      console.log("当前时间戳==>", nowTime);
      //上次登录时间戳
      let storageTime = localStorage.getItem("storageTime");
      console.log("上次登录时间戳==>", storageTime);
      //过期时间戳
      let endTime = Number(storageTime) + 1000 * 3600 * 24 * 5;
      // let endTime = Number(storageTime) + 20000;
      console.log("过期时间戳==>", endTime);
      if (nowTime > endTime) {
        // console.log("过期了");
        // localStorage.clear();
      } else {
        // console.log("没过期");
      }
    },
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => {});
    // this.endTimeFn();
    // console.log("111");
  },
};
</script>
<style lang="less">
// @import url("./assets/base.less");

* {
  margin: 0;
  padding: 0;
}

body,
html {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  width: 100%;
  height: 100%;
}
</style>
